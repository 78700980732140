import React from 'react'

// Components
import Layout from 'components/layout/Layout'

// Libs
import styled from 'styled-components'
import { Link } from 'gatsby'

const Banner = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  padding: 150px 0 50px;

  & h1 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: 800;
  }

  & h1,
  p,
  a {
    color: ${({ theme }) => theme.color.light};
  }

  & a {
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.color.light};
    }
  }
`

function NotFoundPage() {
  return (
    <Layout locale="nl_NL" translations={[]}>
      <Banner>
        <div className="container">
          <h1>404</h1>
          <p>Pagina niet gevonden.</p>
          <Link to="/">Terug naar home</Link>
        </div>
      </Banner>
    </Layout>
  )
}

export default NotFoundPage
